<template>
    <verifySignUpMail/>
</template>

<script>
import verifySignUpMail from '../../components/auth/VerifySignUp'
export default {

    components: {
        verifySignUpMail
    }
}
</script>

<style>

</style>