<template>
<div>
    <div class="login-div" style="background-size:contain">
        <!-- <img src="../../assets/images/logo2.png" height="50" class="mb-4">
    <input type="email" class="form-control mb-4" :placeholder="tran.email">
    <input type="password"   class="form-control mb-4"          :placeholder="tran.password">
    <input type="button" class="submit-btn" :value="tran.login">
        <p class="mt-4">{{tran.forgotPassword}}</p> -->

        <div class="row">
            <div class="col-lg-8 col-12">

            </div>
            <div class="col-lg-4 col-12 login-container text-center">
                <div>
                    <img src="../../assets/images/logo.png" height="70" class="mt-60" @click="sendToMarket">
                </div>
                    <div class="form-div">

                        <form  class="form">
                            <h4 class="mt-2 head-font">{{tran.emailVeri}}</h4>

                            <p class="pt-3"><b>{{userVerifiedEmail}}</b></p>
                            <p class="mt-2">{{tran.enterCode}}</p>
                            <div class="form-group mt-4">
                                <label class="form-label" for="first" @click.prevent="focusOnInput($event)">{{tran.verificationCode}}</label>
                                <input id="first" class="form-input text-center" type="text"  autocomplete="off" v-model="formdata.token"  @keydown.enter.prevent="checkEnterButton" />
                                    </div>

                                <div class="form-group">
                                    <div class="form-group">
                                        <button type="button" class="submit-btn mt-2" :class="{'fade-elem' : loader}" @click.prevent="verifyUserToken"> {{ loader ? '' :  tran.verify }}  <span class="loader mt-1" v-if="loader"></span>
                                        </button>
                                    </div>
                                </div>
                                <p class="mt-4 underline" @click="resendVerificationCode">{{tran.resendCode}} </p>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import trans from '../../../translations'
import {
    mapState
} from "vuex";

import {userNotification} from "../dashboard/mixins/notifyUser.js";
export default {
         mixins:[userNotification],
    data() {

        return {
            tran: {},
            hideEye: false,
            showEye: true,
            hideInput: 'password',
            userVerifiedEmail:''
        }
    },

watch:{
openNotification(){

    if(this.openNotification){

         this.$toast.success({
          title: this.tran.success,
          timeOut: 5000,
            icon:require("../../assets/images/successicon.png"),
          message: this.tran.verificationCodeResend,
          position: "top center",
        });

           this.$store.commit("auth/stoptNotification");
  
  }
}

},

    computed: {
        ...mapState({

            formdata: state => state.auth.signUp,
            loader: state => state.auth.loader,
            openNotification: state => state.auth.openNotification,
            notifyMessage: state => state.auth.notifyMessage,

        }),
    },

    methods: {
          sendToMarket(){
            this.$router.push('/market')
        },

        verifyUserToken() {
            this.$store.dispatch('auth/verifySignUpToken');
        },

        resendVerificationCode() {

            this.$store.dispatch('auth/resendVerificationCode');

        },
        focusOnInput(e) {

            e.target.parentNode.querySelector('.form-input').focus();
        },
        showInputText() {
            this.hideInput = 'text';
            this.showEye = false
            this.hideEye = true
        },

        hideInputText() {
            this.hideInput = 'password';
            this.showEye = true
            this.hideEye = false
        },

        checkEnterButton() {
            if (event.keyCode === 13) {
                this.verifyUserToken();
            }
        },
    },

    mounted() {

        this.tran = trans

        let userEmail = localStorage.getItem("userEmail")
 this.$store.commit('auth/stopLoader')
        this.userVerifiedEmail = userEmail

    }
}
</script>

<style>

</style>
